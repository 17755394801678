<template>
  <div>
    <list-template
      :search-config="searchConfig"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onAdd="$router.push('./add')"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
      @onChange="onChange" />
    <Dialog :visible.sync="visible" title="历史记录" :showFooter="false">
      <TimeLine :list="list" reverse v-loading="dialogLoading"></TimeLine>
    </Dialog>
  </div>
</template>
<script>
import { tableListMixin } from '@/utils/mixins'
import Dialog from "@/components/common/Dialog";
import TimeLine from "@/components/common/TimeLine";
import { system_history } from "@/api";
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin],
  components: { Dialog, TimeLine },
  data() {
    return {
      searchConfig: [
        { placeholder: '搜索视频分类',  prop: 'category_name' },
        { placeholder: '搜索类别名称', prop: 'type_list' },
      ],
      tableConfig: [
        { prop: 'category_name', label: '视频分类' },
        { prop: 'type_list', label: '类别名称' },
        { label: '操作', width: '220px', handle: true }
      ],
      visible: false,
      dialogLoading: false,
      list: []
    }
  },
  methods: {
    getData() {
      this.getDataApi('/api2/api/video/list-category')
    },
    handleClick(row, handle) {
      const { id } = row
      const { type } = handle
      if (type === 'edit') {
        this.$router.push({ path: './edit', query: { id: row.id } })
      } else if (type === 'viewHistory') {
        this.visible = true
        this.getHistory(id)
      }
    },
    getHistory(id) {
      this.list = []
      this.dialogLoading = true
      system_history({ tbl_name:'video_category', tbl_id: id }).then(res => {
        const { list } = res
        this.list = list.map(i => ({ content: i.change_detail }))
      }).finally(() => this.dialogLoading = false)
    },
  }
}
</script>
<style lang="scss" scoped></style>
